export const environment = {
  production: false,
  ENV_NAME: 'Staging',
  loggerUrl: 'https://logger-apm.360techx.co',
  apiNoti:'https://api-tms-staging.360techx.co/api',
  api360Noti:'https://api-staging.360truck.co',
  apiDocgen:'https://api-tms-staging.360techx.co/api',
  apiMatching:'https://api-tms-staging.360techx.co/api',
  apiUrl: 'https://api-tms-staging.360techx.co/api',
  // apiUrl: 'http://localhost:4000/api',
  api360V2: 'https://api-staging.360truck.co',
  api360V3: 'https://apiv3-staging.360truck.co',
  brain360: 'https://tms.360brain.co/planning/planner/plan?id=1',
  loginUrl: "https://identity-staging.360techx.co/login/oauth2/authorize",
  IdentityUrl: "https://identity-staging.360techx.co",
  IdentityAPIUrl: "https://identity-staging.360techx.co/api",
  IdentityAPIUrl_V2 : "https://identity-staging.360techx.co/api/v2",
  IdentityAPIUrl_V3 : "https://identity-staging.360techx.co/api/v3",
  IdentityAPIUrl_V4 : "https://identity-staging.360techx.co/api/v4",
  IdentityAPIUrl_V6 : "https://identity-staging.360techx.co/api/v6",
  FileUrlBaseReplace : 'https://file.tms-staging.360techx.co',
  IdentityBearerToken: "Bearer ff7725f6c4f05b87700aa00ebb2a5448f32461ec91db528d323f833471250c107b2b5b4c05cd18f923eac0c624984f1d33b4df6d37a7aa2ce3d0073d70e4867e",
  web: "http://tms-staging.360techx.co",
  webBasic: "https://tms-staging.360techx.co",
  web360: 'https://webv3-staging.360truck.co',
  webBase360: 'https://webv3-staging.360truck.co',
  setting_AWS: {
    Cloudfront: {
      SignatureKey : 'https://fileserver-staging.360techx.co/Auth/TSTAuth-Signature-EncJson',
      TSTSignatureKey : 'https://fileserver-staging.360truck.co/Auth/TSTAuth-Signature-EncJson',
      Fileserver360THECX:'https://fileserver-staging.360techx.co/api/CFSign/Cookie/CustomV3?resourceDomain=360techx.co&resourceUri=file.tms-staging.360techx.co',
      Fileserver360TRUCK: 'https://fileserver-staging.360truck.co/api/CFSign/Cookie/CustomV3',
      ClearCookie360THECX: 'https://fileserver-staging.360techx.co/api/CFSign/ClearCookieV2',
      ClearCookie360TRUCK: 'https://fileserver-staging.360truck.co/api/CFSign/ClearCookie'
    }
  }, firebaseConfig: {
    apiKey: "AIzaSyDTUNQKdNNf4QFQLfcNACE-DC2Y5kina_Y",
    authDomain: "tms-notifications.firebaseapp.com",
    databaseURL: "https://tms-notifications-default-rtdb.firebaseio.com/",
    projectId: "tms-notifications",
    storageBucket: "tms-notifications.appspot.com",
    messagingSenderId: "811028193638",
    appId: "1:811028193638:web:ecbcb06a635b67395c68a7"
  },
  crypto_key : "360@tst@truck_SecertKeyCryto",
  endpoint_planner:"https://tst-techx-cinderella-stg-4i5wveuswa-as.a.run.app",
  // IdentityV2Url: "https://tst-identity-v2-stg-4i5wveuswa-as.a.run.app",
  IdentityV2Url: "https://tst-identity-v2-stg-4i5wveuswa-as.a.run.app/auto/v1/authorize",
  IdentityV2CallbackUrl: 'https://api-tms-staging.360techx.co/api/v2/auth/callback',
  planingSheraEndpoint:"https://tst-techx-planner-stg-4i5wveuswa-as.a.run.app",
  planningSheraURL : "https://tst-techx-planner-stg-4i5wveuswa-as.a.run.app/workspace/?clientid=",
  tripStatusURl:"https://tst-techx-dashboard-qas-4i5wveuswa-as.a.run.app/operation/status/?clientid=",
  tripSummaryURl:"https://tst-techx-dashboard-qas-4i5wveuswa-as.a.run.app/operation/summary/?clientid=",
  tripHistoryReportURl:"https://tst-techx-dashboard-qas-4i5wveuswa-as.a.run.app/operation/tripdetail/?clientid=",
  kpiWorkStatus:"https://tst-techx-dashboard-qas-4i5wveuswa-as.a.run.app/kpi/workstatus?clientid=",
  dashboardUrl: "https://tst-techx-dashboard-qas-4i5wveuswa-as.a.run.app",
  queueEndpoint:"https://tst-techx-queue-stg-4i5wveuswa-as.a.run.app",
  monitorUrl:"https://tst-techx-monitor-prd-4i5wveuswa-as.a.run.app/?clientid=",
  apiMicrosoft365Url:"https://tst-techx-excel365-stg-4i5wveuswa-as.a.run.app/api",
  ordersUrl:"https://tst-techx-order-stg-1072140069354.asia-southeast1.run.app/api",
  interfaceUrl: "https://tst-techx-interface-stg-46834372927.asia-southeast1.run.app/api",
  eh: {
    clientProductId: 1038,
    productNumber: '0000',
  },
  tripDetailURl:"https://tst-techx-dashboard-qas-4i5wveuswa-as.a.run.app/kpi/TripDetail?clientid=",
  bookingImport:"https://tst-techx-truckbooking-stg-4i5wveuswa-as.a.run.app/upload?clientid=",
  TruckClientID: 1269,
  partnerSeriveApi: "https://tst-techx-admin-prd-utjf2iu33a-as.a.run.app",
  fuelServiceUrl: "https://tst-techx-service-fuel-stg-utjf2iu33a-as.a.run.app/api",
  ehBill:"https://tst-techx-docgen-stg-utjf2iu33a-as.a.run.app/eh/print?bid=",
  oauth: {
    client_id: "31c90dc9-cdf2-4185-af05-d033d7dbe4d7",
    client_secret: "7446b1b7-503d-4d13-9f84-fc4bd17e210d",
    state: "4121f636-0baa-4a92-98db-a11644f16b9d",
    response_type: "code",
    redirect_uri: "https://tms-staging.360techx.co/callback"
  }

  // oauth: {
  //   client_id: "5555",
  //   client_secret: "5555",
  //   state: "4121f636-0baa-4a92-98db-a11644f16b9d",
  //   response_type: "code",
  //   redirect_uri: "http://localhost:4200/callback"
  // }
};
