import { ProvinceModel } from "@app/core/_Models/MasterModel/ProvinceModel";
import { TruckTypeDto } from "@app/modules/truck-management/models/TruckFleet";
import { CompanyTailDocLicenseRequest, CompanyTailDto, CompanyTailLicenseFileResponse } from "./company-tail-model";
import { InsureCargoResponse, InsureCarResponse } from "./company-truck-doc-insure-model";
import { FleetXTruckDto } from "./fleet-x-truck-model";

export interface CompanyTruckDto {
  id: number;
  truckType_ID: number;
  companyTruckVerifyStatus_ID: number;
  headLicenseId: string;
  tailLicenseId: string;
  name: string;
  dimensionX: number | null;
  dimensionY: number | null;
  dimensionZ: number | null;
  dimensionUnitId: number | null;
  usualDriverId: number | null;
  insurCarCompany_ID: number | null;
  insurCargoCompany_ID: number | null;
  insurCar: number | null;
  insurCargo: number | null;
  insurCarExpireDate: string | null;
  insurCargoExpireDate: string | null;
  companyTruckTypeId: number;
  primaryDriverUserId: number | null;
  maxWeight: number | null;
  remarks: string;
  headLicenseProvince_ID: number | null;
  headLicenseProvince: ProvinceModel;
  verifyByUser_ID: number | null;
  verifyComment: string;
  defaultCompanyTail_ID: number | null;
  deletedDate: string | null;
  deletedBy: number | null;
  valid: boolean;
  // carrierRequestTrucks: CarrierRequestTruck[];
  companyTruckPhotos: CompanyTruckPhotoResponse[];
  companyTail: CompanyTailDto;
  companyTruckDocLicense: CompanyTruckDocLicenseResponse;
  companyTailDocLicense: CompanyTailDocLicenseResponse;

  insureCarResponse: InsureCarResponse;
  insureCargoResponse: InsureCargoResponse;
  truckType: TruckTypeDto;
  companyTruckVerifyStatus: CompanyTruckVerifyStatusDto;
  summaryLicense: string;
  summaryDimensionY: number;
  summaryMaxWeight: number;

  fleetItem: FleetXTruckDto;
  shipment: TmsCompanyTruckShipmentResponse;
  isNotAvariable: boolean;
  avariableText: string;
  tailLicense:string;
  fleetNameDisplay: string;
  defaultDriver_ID: number | null;
  defaultDriverName: string;
  shipmentBackLogs: TmsCompanyTruckShipmentResponse[];
  defaultDriverList : DefaultDriverList[];
}

export interface CompanyTruckPhotoRequest {
  companyTruck_ID: number;
  id: number;
  filePath: string;
  valid: boolean;
  isNew: boolean;
  file: any;
}



export interface CompanyTruckDocLicenseRequest {
  companyTruck_ID: number;
  companyDocVerifyStatus_ID: number;
  id: number;
  valid: boolean;
  expireDate: string;
}

export interface CompanyTruckLicenseFileRequest {
  companyTruckDocLicense_ID: number;
  companyDocVerifyStatus_ID: number;
  id: number;
  filePath: string;
  valid: boolean;
  isNew: boolean;
  file: any;
}



export interface CompanyTruckPhotoResponse extends CompanyTruckPhotoRequest {

}

export interface CompanyTruckDocLicenseResponse extends CompanyTruckDocLicenseRequest {
  companyDocVerifyStatus: CompanyDocVerifyStatusDto;
  docVerifyStatusName: string;
  companyTruckLicenseFiles: CompanyTruckLicenseFileResponse[];
  companyDocType:  CompanyDocTypeDto;
}

export interface CompanyTailDocLicenseResponse extends CompanyTailDocLicenseRequest {
  companyDocVerifyStatus_ID: number;
  companyDocVerifyStatus: CompanyDocVerifyStatusDto;
  docVerifyStatusName: string;
  companyTailLicenseFiles: CompanyTailLicenseFileResponse[];
  companyDocType:  CompanyDocTypeDto;
}


export interface CompanyTruckLicenseFileResponse extends CompanyTruckLicenseFileRequest {

}


export interface CompanyTruckVerifyStatusDto {
  iD: number;
  name: string;
  valid: boolean;
}

export interface CompanyDocVerifyStatusDto {
  id: number;
  name: string;
  shortName: string;
}
export class CompanyDocTypeDto {
  id!: number;
  nameEN!: string;
  name!: string;
  isNew!: boolean;
  sequence!: number;
  daysToExpireReminder!: number;
  companyDocVerifyStatus!: CompanyDocVerifyStatusDto;
  companyDocStatus!: CompanyDocStatusDto;
  companyDoc!: CompanyDocDto[];
}

export interface CompanyDocStatusDto {
  companyServiceId: number;
  companyDocTypeId: number;
  companyDocVerifyStatusId: number;
  companyDocVerifyStatus: CompanyDocVerifyStatusDto;
  verifyComment: string;
  insureValue: number | null;
  remainingInsureValue: number | null;
  expireDate: string | null;
  verifyByUser_ID: number | null;
  insuranceCompany_ID: number | null;
  documentDate: string | null;
  driverLicenseNo: string;
  driverType_ID: number | null;
}
export interface CompanyDocDto {
  id: number;
  companyServiceId: number;
  filePath: string;
  companyDocTypeId: number;
  docTypeName: string;
  sequence: number;
  companyDocVerifyStatusId: number;
  valid: boolean;
  files: any;
}

export interface TmsCompanyTruckShipmentResponse {
  shipmentId: number;
  truckBookingId: number | null;
  carrierCompanyTruckId: number | null;
  carrierCompanyTailId: number | null;
  startDate: string | null;
  endDate: string | null;
}

export class AddFleetCompanyTruckDto{
  companyTruckType_ID :number | null;
  truckType_ID :number | null;
  truckType_Name :string | null;
  photo :string | null;
  truckList : CompanyTruckDto[] ;
  selectedTruckList : CompanyTruckDto[] = [];
  selectedCompanyTruckIds = [];
  companyTruck: CompanyTruckDto;

}


export class ITruckImportModelResponse implements ITruckImportModel {
  rowIndex: number;
  companyTruckTypeId: number;
  truckTypeId: number;
  truckTypeName: string;
  companyTruckName: string;
  headLicenseId: string;
  headLicenseProvinceName: string;
  headLicenseProvinceId: number;
  headLicenseExpireDate: string;
  tailLicenseId: string;
  tailLicenseProvinceName: string;
  tailLicenseProvinceId: number;
  tailLicenseExpireDate: string;
  width: number;
  length: number;
  height: number;
  maxWeight: number;
  insureCarCompanyId: number;
  insureCarCompanyName: string;
  insureCarExpireDate: string;
  insureCargoCompanyId: number;
  insureCargoCompanyName: string;
  insureCargoExpireDate: string;
  insureCargoValue: number;
}
export interface ITruckImportModel {
  rowIndex: number;
  truckTypeName: string;
  companyTruckName: string;
  headLicenseId: string;
  headLicenseProvinceName: string;
  headLicenseExpireDate:  string | null;
  tailLicenseId: string;
  tailLicenseProvinceName: string;
  tailLicenseExpireDate:  string | null;
  width: number | string | null;
  length: number | string | null;
  height:  number | string | null;
  maxWeight:  number | string | null;
  insureCarCompanyName: string;
  insureCarExpireDate: string | null;
  insureCargoCompanyName: string;
  insureCargoExpireDate:  string | null;
  insureCargoValue: number | string | null;
}

export const TruckImportHeaders = [
  'truckTypeName',
  'companyTruckName',
  'headLicenseId',
  'headLicenseProvinceName',
  'tailLicenseId',
  'tailLicenseProvinceName',
  'width',
  'length',
  'height',
  'maxWeight',
  'insureCargoCompanyName',
  'insureCargoExpireDate',
  'insureCargoValue',
  'insureCarCompanyName',
  'insureCarExpireDate',
  'headLicenseExpireDate',
  'tailLicenseExpireDate'
]


export interface DefaultDriverList {
  driverId: number | undefined;
  fullName: string | undefined;
}
