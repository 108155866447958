import { ShipmentFuelSummaryDto } from '@app/modules/booking/models/2022/shipment-model';
import { ShipmentTrackingStatusDetailModel } from './ShipmentTrackingStatusModel';
import { driverModel } from './driverModel';

export class ShipmentModel {
  shipmentId: number;
  truckBooking : any;
  truckBookingId: string;
  shipmentNoGroup: string;
  customerName: string;
  tmsCarrierCompanyName: string;
  shipmentNo: string;
  truckLicense: string;
  refNo: string;
  
  driverMobileNo: string;
  driverFirstname: string;
  driverLastname: string;

  drivers: driverModel[];

  team: string;
  startDate: Date;
  lastStatus: string;
  lastDate: Date;
  nextETADate: Date;
  lastETADate: Date;
  truckTypeName: string;
  truckTypePhoto: string;
  productRef: string;
  shipmentStatusName: string;
  truckBookingProductRef: string;
  currentStatus: string;
  currentStatusDate: Date;
  currentSequence: number;
  maxSequence: number;
  clientName: string;
  currentDate: Date;
  shipmentStatusId: number;
  shipmentNoDisplay: string;
  shipmentStatusDetails: ShipmentTrackingStatusDetailModel[] = new Array<ShipmentTrackingStatusDetailModel>();
  teamEdit: boolean = true ;
  shipment: ShipmentModel[] = new Array<ShipmentModel>();
  total: TotalModel;
  isRegister : boolean = false;
  fuelSummary : ShipmentFuelSummaryDto;
  clientCarrierId: number | null;
  carrierName: string | null;
}

interface TotalModel {
  all: number;
  onTheWay: number;
  waitForStart: number;
  waiting: number;
  currentTab: number;
}
