import { Component, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService, HttpService } from '@app/core/_Services';
import { bookingSettingModel } from '@app/modules/booking/models/BookingSettingModel';
import { ServicesettingService } from '@app/modules/setting/service/servicesetting.service';
import { User } from '@app/core/_Models/UserModel/User';
import { BookingServiceService } from 'src/app/modules/booking/service/booking-service.service';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-e-pod',
  templateUrl: './e-pod.component.html',
  styleUrls: ['./e-pod.component.scss']
})
export class EPodComponent implements OnInit {
  @Output() selectEpod: EventEmitter<boolean> = new EventEmitter();
  @Input() isDisable: boolean = false;
  @Input() checked: boolean = false;

  constructor(
    private _apiService: HttpService,
    private bs: BookingServiceService,
    private serviceSetting: ServicesettingService,
    private authen : AuthenticationService
  ) {}

  newBookingForm: FormGroup;
  bookingSetting: bookingSettingModel;
  user: User;
  isShow = false;
  
  
  ngOnInit(): void {
    this.isDisable; 
    this.user = this.authen.currentUserValue;
    this.showEpod();
  }
  
  
  
  async showEpod() {
    console.log("Test E-pod");
    var setting = await this.serviceSetting.GetBookingSetting(this.user.ClientID);
    this.isShow = setting.data?.isEpodAllow??true;
  }

  async actionChange(result: boolean) {
    console.log(result)
    this.selectEpod.emit(result);
  }

}
